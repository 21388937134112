



















































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import moment from "moment";
import {Schedule} from "@/entity/Schedule";
import Slick from 'vue-slick'
import 'slick-carousel/slick/slick.css';


//@ts-ignore
import Slider from "bootstrap-slider";
import UnpayedCard from "@/components/card/UnpayedCard.vue";
import VideoItem from "@/components/video/VideoItem.vue";

@Component({
  components: {VideoItem, UnpayedCard,Slick}
})
export default class ScheduleStreamBlockCard extends Vue{
  private isOpenScheduleContainer = false
  private selectedCameraSchedule= {}
  private isMayBeAudio: boolean | null = null;
  private camerasSchedule: any = [];
  private isMutedLesson = true;
  private selectedIndexSchedule : number | null = 0
  private cameraScheduleSlider: any = null
  private nextAvailableLesson1: any = null;
  private actLessonNow: any = {};
  private slider : any = null;

  private isFullScreenSchedule = false;


  @Prop({required : true})
  private schoolClass: any = {}



  @Watch("selectedIndexSchedule",{deep: true,immediate:true})
  onSelectedIndexScheduleChange(newVal : number,oldVal : number){
    // this.stopSchedule(oldVal)
    // this.playSchedule(newVal)
    if (this.activeLessonCameras.length > 0){
      const cam = this.camerasSchedule[newVal]
      //@ts-ignore
      this.isMayBeAudio = !!cam.isAudio;
    }
  }


  get slickOptions(){
    const slickOptions = {
      rows: 1,
      infinite: false,
      arrows: false,
      dots: false,
      useTransform: false,
      slidesPerRow: 1,
    }
    if (this.isFullScreenSchedule){
      slickOptions.useTransform = false
    }

    return slickOptions
  }

  get account(){
    return this.$store.getters.PROFILE
  }

  get lessons(){
    if (this.schoolClass.id != null){
      const lessons = this.$store.getters.LESSONS(this.schoolClass.id)
      return lessons
    }
    return [];
  }

  get filteredLessons(){
    let lessons = this.lessons
    return lessons.filter((lesson: any) => lesson.name != 'Перерыв')

  }


  get activeLessonCameras(){
    if (this.activeLesson){
      return this.activeLesson.cameras
    } else if (this.nextAvailableLesson1){
      return this.nextAvailableLesson1.cameras
    }

    return []
  }

  get isSchoolTimeNotBegin(){
    if (this.timeNow === 0) return true;

    const schedules = this.lessons;
    if (schedules.length > 0){
      const timeBegin = schedules[0]._timeBegin;
      //@ts-ignore
      return this.timeNow < moment(timeBegin.date).toDate().getTime();
    } else {
      return true;
    }

  }

  get isSchoolTimeAlreadyEnd(){
    const time = this.$store.getters.TIME_NOW
    if (time === 0) return true;


    const schedules = this.lessons;
    if (schedules.length > 0){
      const timeEnd = schedules[schedules.length-1]._timeEnd;
      //@ts-ignore
      return time > moment(timeEnd.date).toDate().getTime();
    } else {
      return true;
    }
  }

  get activeLesson(){
    if (this.schoolClass.id != null){
      const activeLesson = this.$store.getters.ACTIVE_LESSONS(this.schoolClass.id)
      if (this.selectedIndexSchedule == null){
        this.selectedIndexSchedule = 0
      }
      if (activeLesson != null && activeLesson != false){
        //@ts-ignore
        if (activeLesson.name === 'Перерыв' || activeLesson.name === 'Тихий час'){
          this.isMayBeAudio = false
        } else {
          //@ts-ignore
          this.isMayBeAudio = !!activeLesson?.cameras[this.selectedIndexSchedule].isAudio;
        }

      }
      if (activeLesson != false && this.actLessonNow?.id != activeLesson.id && activeLesson != null){
        this.closeAllFullscreen()
        this.selectedIndexSchedule = 0
        this.actLessonNow = activeLesson
        console.log(activeLesson.cameras, "камеры блят")
        this.camerasSchedule = [...activeLesson.cameras]
        this.initScheduleCarousel()
        this.initSlider()
        console.log("aim duit")
      } else if (activeLesson == false){
        if (this.isSchoolTimeAlreadyEnd && this.isPreShowEndSchool ){
          const lesson1 = this.lessons[this.lessons.length-1]
          //@ts-ignore
          this.isMayBeAudio = !!lesson1?.cameras[this.selectedIndexSchedule].isAudio;
          if ((this.nextAvailableLesson1 == null || this.nextAvailableLesson1._id !== lesson1._id)){
            this.closeAllFullscreen()
            this.selectedIndexSchedule = 0
            this.nextAvailableLesson1 = lesson1
            this.camerasSchedule = [...lesson1.cameras]
            this.initScheduleCarousel()
            return this.$store.getters.ACTIVE_LESSONS(this.schoolClass.id)
          }
          return this.$store.getters.ACTIVE_LESSONS(this.schoolClass.id)
        } else if (this.isSchoolTimeNotBegin && this.secondsToBeginSchool > 0 && this.isPreShowBeginSchool){
          const lesson1 = this.lessons[0]
          //@ts-ignore
          this.isMayBeAudio = !!lesson1?.cameras[this.selectedIndexSchedule].isAudio;
          if ((this.nextAvailableLesson1 == null || this.nextAvailableLesson1._id !== lesson1._id)){
            this.closeAllFullscreen()
            this.selectedIndexSchedule = 0
            this.nextAvailableLesson1 = lesson1
            this.camerasSchedule = [...lesson1.cameras]
            this.initScheduleCarousel()
            return this.$store.getters.ACTIVE_LESSONS(this.schoolClass.id)
          }
          return this.$store.getters.ACTIVE_LESSONS(this.schoolClass.id)
        }
        else if (this.isRelax){
          const nextAvailableLesson =  this.nextAvailableLesson
          //@ts-ignore
          this.isMayBeAudio = !!nextAvailableLesson.cameras[this.selectedIndexSchedule].isAudio;
          if (this.nextAvailableLesson1 == null || this.nextAvailableLesson1._id !== nextAvailableLesson._id){
            this.closeAllFullscreen()
            this.selectedIndexSchedule = 0
            this.nextAvailableLesson1 = nextAvailableLesson
            this.camerasSchedule = [...nextAvailableLesson.cameras]


            this.initScheduleCarousel()
            return this.$store.getters.ACTIVE_LESSONS(this.schoolClass.id)
          }
          return this.$store.getters.ACTIVE_LESSONS(this.schoolClass.id)
        } else {
          this.closeAllFullscreen()

          this.isMutedLesson = true
          this.isMayBeAudio = false
          this.nextAvailableLesson1 = null
          this.camerasSchedule = [];
        }
      }

      return this.$store.getters.ACTIVE_LESSONS(this.schoolClass.id)
    }

  }


  get timeNow(){
    return this.$store.getters.TIME_NOW
  }


  get statusScheduleInClass(){
    const profileSchool = this.$store.getters.SCHOOL
    console.log(this.lessons)
    if (!this.$store.getters.PAY_STATUS ){
      return 3
    } else if (profileSchool != null && profileSchool.isHoliday){
      return 6
    } else if (this.lessons.length === 0){
      console.log("wtf")
      return 7
    }
    else if (this.isSchoolTimeNotBegin){
      return 0
    } else if (this.activeLesson._name === 'Перерыв'){
      var index = null
      //@ts-ignore
      // const camera : any = this.cams[this.swiper?.realIndex]
      // if (camera == null || !camera.isKitchen){
      //     this.cams.forEach((x : any, index1) => {
      //         if (x.isKitchen) {
      //             index = index1;
      //         }
      //     });
      //
      //     console.log("index",index)
      //     if (index != null){
      //         // @ts-ignore
      //         this.swiper?.slideTo(index)
      //     }
      // }
      return 4
    } else if (this.activeLesson._name === 'Тихий час'){
      return 8
    } else if (this.isRelax){
      return 1
    } else if (this.isSchoolTimeAlreadyEnd){
      return 2
    }

    else {
      return 5
    }
  }


  get activeLessonName() : string{
    switch (this.statusScheduleInClass) {
      case 0:
        return "Ждем начала занятий"
      case 1:
        return "Сейчас идет перемена"
      case 2:
        return "Учебный день окончен"
      case 3:
        return ""
      case 4:
        return "Обеденный перерыв"
      case 5:
        return this.activeLesson._name
      case 8:
        return "Тихий час"
      default:
        console.log(this.statusScheduleInClass)
        return ""
    }
    return ""
  }

  get nextAvailableLesson(){
    //@ts-ignore
    const availableLessons = this.lessons.filter(schedule => schedule._availableStatus === 2);
    if (availableLessons.length === 0) return false;
    return availableLessons[0];
  }

  get beginTimeString(){
    if (this.schoolClass.id != null){
      const lessons = this.$store.getters.LESSONS(this.schoolClass.id)
      if (lessons.length > 0){
        const date = moment(lessons[0]._timeBegin.date)
        // console.log(date)
        return date.format("HH:mm")
      }
    }
    return ""
  }

  get endTimeString(){
    if (this.schoolClass.id != null){
      const lessons = this.$store.getters.LESSONS(this.schoolClass.id)
      if (lessons.length > 0){
        const date = moment(lessons[lessons.length-1]._timeEnd.date)
        return date.format("HH:mm")
      }
    }
    return ""
  }



  get isLessonShow(){
    if (this.lessons.length > 0){
      if (this.activeLesson || this.statusScheduleInClass == 1 || (this.statusScheduleInClass == 0 && this.isPreShowBeginSchool) || (this.statusScheduleInClass == 2 && this.isPreShowEndSchool)) {
        return true
      }
    }
    return false
  }


  get secondsTo(){
    if (this.lessons.length > 0){
      const timeNow = this.$store.getters.TIME_NOW
      if (this.activeLesson){
        return Math.round((moment(this.activeLesson._timeEnd.date).toDate().getTime()-timeNow)/1000)
      } else {
        const lesson = this.nextAvailableLesson
        if (lesson){
          return Math.round((moment(lesson._timeBegin.date).toDate().getTime()-timeNow)/1000)
        }
      }
    }
    return -1;
  }


  get secondsToEndSchool(){
    if (this.lessons.length > 0){
      const timeNow = this.$store.getters.TIME_NOW
      const lesson = this.lessons[this.lessons.length-1]
      if (lesson){
        return Math.round((moment(lesson._timeEnd.date).toDate().getTime()-timeNow)/1000)
      }
    }
    return -1;
  }


  get secondsToBeginSchool(){
    if (this.lessons.length > 0){
      const timeNow = this.$store.getters.TIME_NOW
      const lesson = this.lessons[0]
      if (lesson){
        return Math.round((moment(lesson._timeBegin.date).toDate().getTime()-timeNow)/1000)
      }
    }
    return -1;
  }

  get isPreShowBeginSchool(){
    if (this.lessons.length > 0){
      const lesson = this.lessons[0]
      if (lesson){
        return Math.abs(this.secondsToBeginSchool) <= (lesson.durationBreak * 60)
      }
    }
    return false;
  }

  get isPreShowEndSchool(){
    if (this.lessons.length > 0){
      const lesson = this.lessons[this.lessons.length-1]
      if (lesson){
        return Math.abs(this.secondsToEndSchool) <= (lesson.durationBreak * 60)
      }
    }
    return false;
  }


  get howManyLessonsString(){
    if (this.filteredLessons.length > 0){
      const count = this.filteredLessons.filter((lesson : any) => lesson._availableStatus == 2).length
      const correctedString = this.declToString(count)
      if (count != 0 && count === this.filteredLessons.length){
        return `Сегодня ${count} ${correctedString}`
      } else if ( (count === 1 && !this.activeLesson)  || (count === 0 && this.activeLesson)){
        return "Последний урок"
      } else if (count === 0){
        return ""
      } else {
        return `Еще ${count} ${correctedString}`
      }
    }
    return ""
  }

  get fancyTimeTo(){
    const seconds = this.secondsTo
    return (seconds !== -1) ? this.fancyTimeFormat(seconds) : "00:00"
  }


  get fancyTimePreEndTo(){
    let seconds = -1
    if (this.lessons.length > 0){
      const lesson = this.lessons[this.lessons.length-1]
      if (lesson){
        seconds = (lesson.durationBreak * 60) -  Math.abs(this.secondsToEndSchool)
      }
    }
    return (seconds !== -1) ? this.fancyTimeFormat(seconds) : "00:00"
  }


  fancyTimeFormat(time : number)
  {
    if (time < 0) return "00:00";
    // Hours, minutes and seconds
    var hrs = ~~(time / 3600);
    var mins = ~~((time % 3600) / 60);
    var secs = ~~time % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";

    ret += "" + (mins < 10 ? "0" : "");
    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
  }

  declToString(count: number){
    if (count === 1){
      return "урок"
    } else if (count > 1 && count < 5){
      return "урока"
    } else {
      return "уроков"
    }
  }


  get secondsToEndSchoolDay(){
    const lessons = this.$store.getters.LESSONS(this.schoolClass.id)
    const time = this.$store.getters.TIME_NOW
    if (lessons.length > 0){
      return Math.round((time - (moment(lessons[0]._timeBegin.date).toDate().getTime())) / 1000)
    }
    return 0
  }


  get isRelax(){
    if (!(this.isSchoolTimeNotBegin || this.isSchoolTimeAlreadyEnd)){
      //@ts-ignore
      return this.lessons.filter(lesson => lesson.availableStatus === Schedule.STATUS_BEGIN).length === 0
    } else {
      return false;
    }
  }

  isIterable(obj:any) {
    // checks for null and undefined
    if (obj == null) {
      return false;
    }
    return typeof obj[Symbol.iterator] === 'function';
  }

  closeAllFullscreen(){
    if (this.isIterable(this.$refs.camsContainerSchedule)){
      //@ts-ignore
      for (const video of this.$refs.camsContainerSchedule){
        //@ts-ignore
        if (video.isFullScreen){
          //@ts-ignore
          video.exitScreen()
        }
      }
    }
  }

  initScheduleCarousel(){
    let currIndex = 0

    if (this.$refs.slider){
      //@ts-ignore
      this.$refs.slider.destroy()

    }

    this.$nextTick(() => {
      //@ts-ignore
      this.$refs.slider.create(this.slickOptions)
      //@ts-ignore
      this.$refs.slider.goTo(currIndex, true)
    })
  }


  initSlider(){
    if (this.schoolClass.id != null){
      const lessons = this.$store.getters.LESSONS(this.schoolClass.id)
      const lastLesson = lessons[lessons.length-1]
      const firstLesson = lessons[0]
      if (![3,6,7].includes(this.statusScheduleInClass)){
        if (lessons.length > 0 && this.slider == null){
          const seconds = Math.round((moment(lastLesson._timeEnd.date).toDate().getTime()-moment(firstLesson._timeBegin.date).toDate().getTime())/1000)
          console.log("seconds max")


          this.slider = new Slider("#slider"+this.schoolClass.id,{
            orientation: 'vertical',
            min: 0,
            max: seconds,
            step: 1
          })

          let value = 0;
          setInterval(() => {
            this.slider.setValue(this.secondsToEndSchoolDay)
          },1000)
        }
      }
    }

  }


  playSchedule(index:any){
    console.log(this.$refs.camsContainerSchedule)
    console.log("camsContainerSchedule",this.$refs.camsContainerSchedule)
    if (this.$refs.camsContainerSchedule == null) return;
    //@ts-ignore
    this.$refs.camsContainerSchedule[index].playVideo();
  }

  stopSchedule(index: any){
    console.log(this.$refs.camsContainerSchedule)
    if (this.$refs.camsContainerSchedule == null) return;
    //@ts-ignore
    this.$refs.camsContainerSchedule[index].stopVideo();
  }

  muteSchedule(){
    //@ts-ignore
    const cam = this.camerasSchedule[this.selectedIndexSchedule]
    if (cam != null && cam.isAudio){
      this.isMutedLesson = true
      //@ts-ignore
      for(let cam of this.$refs.camsContainerSchedule )
          //@ts-ignore
        cam.mute()
      // if (!this.isMutedCams){
      //     this.isMutedCams = true
      // }
    }
  }


  changeMuteLessons(isMuted : any){
    //@ts-ignore
    // const cam = this.camerasSchedule[this.selectedIndexSchedule]
    // if (cam.isAudio){
    //     this.isMutedLesson = !this.isMutedLesson
    //     // if (!this.isMutedCams){
    //     //     this.isMutedCams = true
    //     // }
    // }

    this.isMutedLesson = isMuted
    if (!this.isMutedLesson){
      this.$emit("audio")
    }
  }


  handleAfterChange (event: any, slick : any, currentSlide : any) {
    console.log(event)
    //@ts-ignore
    this.selectedIndexSchedule = currentSlide
    this.isMutedLesson = true
    //@ts-ignore
    const camera = this.camerasSchedule[this.selectedIndexSchedule]
    this.selectedCameraSchedule = {...camera}
  }

  async onChangeFullScreen(isFullscreen: any){
    this.isFullScreenSchedule = isFullscreen
    if (isFullscreen){
      this.$emit("audio")
    }
  }


  toggleScheduleContainer(){
    this.isOpenScheduleContainer = !this.isOpenScheduleContainer
  }


  async beforeDestroy(){
    //@ts-ignore
    if (this.$refs.camsContainerSchedule != null && this.$refs.camsContainerSchedule.length > 0){
      //@ts-ignore
      this.$refs.camsContainerSchedule[this.selectedIndexSchedule].fullStop();
    }
    // if (this.cameraScheduleSlider != null){
    //     this.cameraScheduleSlider.destroy();
    //
    // }
    this.nextAvailableLesson1 = null;
    this.camerasSchedule = [];
    this.actLessonNow = {};

    this.selectedCameraSchedule = {}
  }


  mounted(){
    setTimeout(() => {
      this.initSlider()
    },2000)
  }

}
