








































































import {Component, Vue, Watch} from "vue-property-decorator";
import MaterialButton from "@/components/button/MaterialButton.vue";
import CamerasListModal from "@/components/modal/CamerasListModal.vue";
import PaymentSuccessModal from "@/components/modal/PaymentSuccessModalNew.vue";
import PaymentFailedModal from "@/components/modal/PaymentFailedModalNew.vue";
import EditCardModalNew from "@/components/modal/EditCardModalNew.vue";
import DeleteCardModalNew from "@/components/modal/DeleteCardModalNew.vue";
import ConnectAutoPaymentModal from "@/components/modal/ConnectAutoPaymentModal.vue";
import DisconnectAutoPaymentModal from "@/components/modal/DisconnectAutoPaymentModal.vue";
import HlsPlayerNew from "@/components/HlsPlayerNew.vue";
import moment from "moment";
import {WebsocketFactory} from "@/custom/WebsocketFactory";
import HlsPlayer from "@/components/HlsPlayer.vue";
import Loader from "@/components/loader/loader.vue";
import "owl.carousel/dist/owl.carousel"
import "owl.carousel/dist/assets/owl.carousel.css"
//@ts-ignore
import "tiny-slider/dist/tiny-slider.css"
import ScheduleStreamBlockCard from "@/components/card/ScheduleStreamBlockCard.vue";
import UnpayedCard from "@/components/card/UnpayedCard.vue";
import VideoItem from "@/components/video/VideoItem.vue";




@Component({
    components: {
      VideoItem,
        UnpayedCard,
        ScheduleStreamBlockCard,
        Loader,
        HlsPlayer,
        HlsPlayerNew,
        DisconnectAutoPaymentModal,
        ConnectAutoPaymentModal,
        DeleteCardModalNew,
        EditCardModalNew, PaymentFailedModal, PaymentSuccessModal, CamerasListModal, MaterialButton}
})
export default class DashboardViewNew extends Vue{
    private profileSchool= {}
    private cams= []
    private selectedIndex: number | null = null
    private index = 0
    private selectedCamera= {}

    private swiper = null
    private ws!: WebSocket;
    private isLoading = true

    private isMutedCams = true;

    private isMayBeAudioCam : boolean | null = null;

    private keys : any = {37: 1, 38: 1, 39: 1, 40: 1};
    private supportsPassive : any = false
    private wheelOpt : any = false
    private wheelEvent : any = ''

   private isFullScreenCamera: boolean = false;



    @Watch("selectedIndex",{deep: true,immediate:true})
    onSelectedIndexChange(newVal : number,oldVal : number){
        // this.stop(oldVal)
        // this.play(newVal)
        if (this.cams.length > 0){
            const cam = this.cams[newVal]
            //@ts-ignore
            this.isMayBeAudioCam = !!cam.isAudio;
        }
    }



    get schoolAvatar(){
        //@ts-ignore
        if (this.profileSchool.avatar === null)
            return ""
        //@ts-ignore
        return this.profileSchool.avatar
    }


    get isMobile(){
        return screen.width < 992
    }


    get cameraName(){
        if (this.cams.length > 0){
            //@ts-ignore
            return this.cams[this.selectedIndex]._name
        }
    }





    get account(){
        return this.$store.getters.PROFILE
    }




    onChangeAudio(index: any = null){
        if (index != null){
            this.isMutedCams = true
          //@ts-ignore
          for(let cam of this.$refs.camsContainer){
            //@ts-ignore
            cam.mute()
          }
        }


        //@ts-ignore
        for (let refFromFor of this.$refs["scheduleBlock"]){
            //@ts-ignore
            if (index == null || refFromFor !== this.$refs["scheduleBlock"][index]){
                //@ts-ignore
                refFromFor.muteSchedule()
            }
        }
    }







    setSettingsAnimateChatra(){
        //@ts-ignore
        this.isMobileNew = (window.innerWidth < 960)
        window.addEventListener("resize",() => {
            // @ts-ignore
            this.isMobileNew = (window.innerWidth < 960)
        })
        //@ts-ignore
        this.enableChatra()
        //@ts-ignore
        if (this.isMobileNew){
            let exists = setInterval(() => {
                if (document.getElementById("chatra") != null){
                    clearInterval(exists)

                    // @ts-ignore
                    var tween1 = new TimelineLite();

                    tween1
                        .to('#chatra',1,{autoAlpha: 4})

                    // @ts-ignore
                    var phone1Controller  = new ScrollMagic.Controller();
                    // @ts-ignore
                    var scene1 = new ScrollMagic.Scene({
                        triggerElement: ".info--container",
                        duration: 100,
                        offset: -200
                    })
                        .setTween(tween1)
                        .addTo(phone1Controller);
                }
            },100)
        }
    }

    initCarousel(){
        //@ts-ignore
        var owl = $('.owl-carousel-cameras');
        //@ts-ignore
        owl.owlCarousel({
            items:1,
            dotsContainer : '.slider-icons--container',
        })

        //@ts-ignore
        owl.on('changed.owl.carousel', (event) =>  {
            //@ts-ignore
            this.selectedIndex = event.item.index
            this.isMutedCams = true
            //@ts-ignore
            const camera = <object> this.cams[this.selectedIndex]
            this.selectedCamera = {...camera}
        })


        if (this.cams.length > 0){
            // this.play(0)

            //@ts-ignore
            const camera = <object> this.cams[this.selectedIndex]
            this.selectedCamera = {...camera}
        }
    }

  isIterable(obj : any) {
    // checks for null and undefined
    if (obj == null) {
      return false;
    }
    return typeof obj[Symbol.iterator] === 'function';
  }



    onChangeFullScreen(isFullscreen : any){
      if (isFullscreen && this.isIterable(this.$refs.scheduleBlock)){
        //@ts-ignore
        for (const video of this.$refs.scheduleBlock){
          //@ts-ignore
          video.muteSchedule()
        }
      }
      var owl = $('.owl-carousel-cameras');
      this.isFullScreenCamera = isFullscreen
      if (this.isFullScreenCamera){
        //@ts-ignore
        owl.trigger("destroy.owl.carousel")
        //@ts-ignore
        document.getElementsByClassName("owl-carousel-cameras")[0].style.display = 'block'
      } else if (!this.isFullScreenCamera) {
        const div = document.createElement("div")
        div.classList.add("slider-icons--container")
        div.style.width = "100%"
        div.style.marginTop = "21px"
        div.style.display = "flex"
        div.style.justifyContent = "center"
        div.style.height = "4px"
        const streamViewDiv = document.getElementsByClassName("select-camera--container")[0]
        //@ts-ignore
        streamViewDiv.parentElement.insertBefore(div,streamViewDiv)
        //@ts-ignore
        owl.owlCarousel({
          items:1,
          dotsContainer : '.slider-icons--container',
          startPosition: this.selectedIndex
        })
      }
    }


    play(index : any){
        this.index = index
        console.log(this.$refs.camsContainer)
        if (this.$refs.camsContainer == null) return;
        //@ts-ignore
        this.$refs.camsContainer[index].playVideo();
    }


    stop(index : any){
        console.log(this.$refs.camsContainer)
        if (this.$refs.camsContainer == null) return;
        //@ts-ignore
        this.$refs.camsContainer[index].stopVideo();
    }


    showCamerasModal(){
        //@ts-ignore
        this.$modal.show("CamerasListModal",{cameras: this.cams,selectedId : this.selectedCamera?._id})
    }

    onSelectCamera(camera: any,index : number){
        this.selectedCamera = {...camera}
        //@ts-ignore
        var owl : any = $('.owl-carousel');
        owl.trigger( 'to.owl.carousel', [index] )
        // this.swiper?.slideTo(index)
    }

    async initSchedule(){
        //@ts-ignore
        for (let schoolClass of this.account.myClasses){
            this.downloadSchedule(this.account._schoolId,schoolClass.id)
        }
    }


    async downloadSchedule(schoolId : number,schoolClassId : number){
        const lessons = this.$store.getters.LESSONS(schoolClassId);
        const lessonsStr = JSON.stringify(lessons);
        const crc32 = this.crc32(lessonsStr);
        await this.$store.dispatch("UPDATE_LESSONS",{schoolId : schoolId,classId :schoolClassId,crc32: crc32})
    }

    async onMessage(event: any){
        // alert("Получены данные " + event.data);
        const data = JSON.parse(event.data)
        switch (data.id) {
            case 'timeUpdated':
                const time = moment(data.time)
                // console.log(time)
                this.$store.commit("SET_TIME_NOW",time)
                // await this.initSchedule()
                this.isLoading = false
                // console.log(this.time)
                // await this.workWithSchedule()
                break;
            case 'scheduleUpdated':
                await this.initSchedule()
                break;
        }
    }

    onError(event: any){
        console.error('Socket encountered error: ', event.message, 'Closing socket');
        this.ws.close();
    }

    onOpen(){
        console.log("websocket open")
    }

    onClose(e : any){
        console.log(e)
        console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
        if (e.code != 1000){
            setTimeout(() => {
                this.connectSocket();
            }, 1000);
        }
    }

    sendEnterMessage(ws: WebSocket){
        this.sendSocketMessage(ws,JSON.stringify({
            "id" : "EnterToTranslation",
            //@ts-ignore
            "userId" : this.account._id,
          "classesId": this.account.myClasses
        }));
    }


    sendSocketMessage(ws: WebSocket,message : string){
        try{
            if (ws.readyState === 1){
                ws.send(message)
                return true;
            } else {
                setTimeout(() => this.sendSocketMessage(ws,message),1000);
            }
        } catch (e) {
            console.log(e)
            return false;
        }
    }


    changeMuteCams(){
        //@ts-ignore
        const cam = this.cams[this.selectedIndex];
        //@ts-ignore
        if (cam.isAudio){
            this.isMutedCams = !this.isMutedCams
            // if (!this.isMutedLesson){
            //     this.isMutedLesson = true
            // }
        }

        if (!this.isMutedCams){
            this.onChangeAudio(null)
        }
    }



    preventDefault(e: any) {
        e.preventDefault();
    }

    preventDefaultForScrollKeys(e: any) {
        if (this.keys[e.keyCode]) {
            this.preventDefault(e);
            return false;
        }
    }

    disableScroll() {
        window.addEventListener('DOMMouseScroll', this.preventDefault, false); // older FF
        window.addEventListener(this.wheelEvent, this.preventDefault, this.wheelOpt); // modern desktop
        window.addEventListener('touchmove', this.preventDefault, this.wheelOpt); // mobile
        window.addEventListener('keydown', this.preventDefaultForScrollKeys, false);
    }

    enableScroll() {
        window.removeEventListener('DOMMouseScroll', this.preventDefault, false);
        window.removeEventListener(this.wheelEvent, this.preventDefault, this.wheelOpt);
        window.removeEventListener('touchmove', this.preventDefault, this.wheelOpt);
        window.removeEventListener('keydown', this.preventDefaultForScrollKeys, false);
    }

    connectSocket(){
        this.ws = WebsocketFactory.make("wss://api.esova.ru:8566/profile",this.onOpen,this.onMessage,this.onError,this.onClose);
        this.sendEnterMessage(this.ws);
    }

    makeCRCTable(){
        var c;
        var crcTable = [];
        for(var n =0; n < 256; n++){
            c = n;
            for(var k =0; k < 8; k++){
                c = ((c&1) ? (0xEDB88320 ^ (c >>> 1)) : (c >>> 1));
            }
            crcTable[n] = c;
        }
        return crcTable;
    }

    crc32(str : string) {
        //@ts-ignore
        var crcTable = window.crcTable || (window.crcTable = this.makeCRCTable());
        var crc = 0 ^ (-1);

        for (var i = 0; i < str.length; i++ ) {
            crc = (crc >>> 8) ^ crcTable[(crc ^ str.charCodeAt(i)) & 0xFF];
        }

        return (crc ^ (-1)) >>> 0;
    };

    get statusScheduleInClass(){
        const profileSchool = this.$store.getters.SCHOOL
        if (!this.$store.getters.PAY_STATUS ){
            return 3
        } else if (profileSchool != null && profileSchool.isHoliday){
            return 6
        }
        else {
            return 5
        }
    }

    async init(){
        try{
            this.isLoading = true
            await this.$store.dispatch("UPDATE_LOCAL_PROFILE")
            if (this.account.myClasses.length  === 0){
              await this.$router.push({name: 'CongratulationView'})
            } else {
              //@ts-ignore
              await this.$store.dispatch("GET_SCHOOL",{id : this.account._schoolId})
              this.profileSchool = this.$store.getters.SCHOOL
              //@ts-ignore
              const cams = this.profileSchool.shared;
              //@ts-ignore
              this.cams = [...cams];
              this.selectedIndex = 0
              // this.selectedIndexSchedule = 0
              setTimeout(() => this.initCarousel(),2000)
              if (this.$store.getters.PAY_STATUS){
                await this.initSchedule();
                // this.initScrollPlay()
                // this.initSlider()

              }
              this.connectSocket()
            }
        } catch (e) {
            console.log(e)
            await this.init()
        }
    }

    async mounted(){
        await this.init()

    }


    async beforeDestroy(){
        this.ws.close(1000)
        //@ts-ignore
        if (this.$refs.camsContainer != null && this.$refs.camsContainer.length > 0){
            //@ts-ignore
            this.$refs.camsContainer[this.selectedIndex].fullStop();
        }

        if (this.swiper != null){
            //@ts-ignore
            this.swiper.destroy()
        }
        this.selectedCamera = {};
        this.cams = [];
        this.profileSchool = {};
    }





}
