<template>
    <modal name="CamerasListModal" width="308px" height="500px" @before-open="onBeforeOpen">
        <div class="modal--view">
            <div class="modal-head--container">
                <a @click="close"><span class="icon-close"></span></a>
            </div>
            <div class="modal-body--container">
                <div class="cameras--list">
                    <div class="camera--list-item" v-for="(camera,index) in cameras" :class="{'active' : camera._id == selectedId}" @click="select(camera,index)">
                        <p>{{camera._name}}</p>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
    export default {
        name: "CamerasListModal",
        data(){
            return {
                cameras : [],
                selectedId : 0
            }
        },
        methods:{
            onBeforeOpen(e){
                this.selectedId = e.params.selectedId
                this.cameras = [...e.params.cameras]
            },

            close(){
                this.$modal.hide("CamerasListModal")
            },


            select(camera,index){
                this.$emit("select",camera,index)
                this.close()
            }
        }
    }
</script>

<style scoped lang="scss">
    .modal--view{
        width: 308px;
        height: 500px;
        background: #FFFFFF;
        border: 0 solid #F2F2F2;
        box-shadow: 0 2px 7px 1px rgba(88,88,88,0.40);
        border-radius: 4px;
        display: flex;
        flex-direction: column;

        .modal-head--container{
            display: flex;
            justify-content: flex-end;
            margin-top: 16px;
            .icon-close{
                margin-right: 16px;
                cursor: pointer;
                color: rgb(161,160,161);
            }


            a{
                &:hover{
                    filter: brightness(87.5%);
                }
            }
        }


        .modal-body--container{
            width: 100%;
            display: flex;
            flex-direction: column;
            .cameras--list{
                width: 305px;
                height: 408px;
                border-top: 1px solid rgba(139,138,141,0.10);
                border-bottom: 1px solid rgba(139,138,141,0.10);
                display: flex;
                flex-direction: column;
                margin-top: 14px;
                overflow: hidden;
                overflow-y: scroll;

                &::-webkit-scrollbar{
                    display: none;
                }

                .camera--list-item{
                    width: 100%;
                    border-bottom: 1px solid rgba(139,138,141,0.20);
                    cursor: pointer;
                    p{
                        font-size: 17px;
                        font-family: Roboto, "sans-serif";
                        font-weight: 300;
                        margin-left: 19px;
                        padding-top: 16px;
                        padding-bottom: 16px;
                        color: #8B8A8D;
                        pointer-events: none;
                    }


                    &.active{
                        cursor: auto;
                        background-color: rgba(0,0,0,0.1);
                    }

                    &:hover{
                        background-color: rgba(0,0,0,0.05);
                    }

                    &.active:hover{
                        background-color: rgba(0,0,0,0.1);
                    }
                }
            }
        }
    }


    *{
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    }
</style>