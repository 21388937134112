<template>
  <div class="audio" v-if="hasAudio">
    <svg
        v-if="volume === 0"
        @click="changeAudio(1)"
        width="10"
        height="11"
        viewBox="0 0 10 11"
        fill="none"
        class="audio__off"
        xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M1.1508 0.164386C0.88495 -0.059936 0.48743 -0.0564106 0.225207 0.185641C-0.0588671 0.447864 -0.0765815 0.890725 0.185641 1.1748L8.58564 10.2748L8.64921 10.3356C8.91506 10.5599 9.31258 10.5564 9.5748 10.3144C9.85887 10.0521 9.87659 9.60928 9.61437 9.32521L1.21437 0.225207L1.1508 0.164386ZM0.64848 3.80419H2.14531L7.14432 9.15941L7.1443 10.1234C7.1443 10.3749 6.99895 10.6035 6.77148 10.7101C6.68354 10.7517 6.5893 10.7718 6.49567 10.7718C6.3474 10.7718 6.20052 10.7209 6.08187 10.6225L2.53666 7.68477H0.64848C0.290398 7.68508 3.4942e-06 7.39453 3.4942e-06 7.03645V4.45282C3.4942e-06 4.09459 0.290398 3.80419 0.64848 3.80419ZM7.14445 1.36592L7.14437 6.08199L3.93587 2.64487L6.08203 0.866479C6.27573 0.705933 6.54433 0.671705 6.77164 0.779145C6.99895 0.885818 7.14445 1.11451 7.14445 1.36592Z"
            fill="#E2E2E2"/>
    </svg>
    <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg" class="audio__on"
         v-else
         @click="changeAudio(0)">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M11.8491 11.3436C12.0307 11.3359 12.2003 11.2527 12.3175 11.1141C13.5833 9.6147 14.2803 7.70779 14.28 5.74486C14.28 3.70473 13.5345 1.74165 12.1811 0.217845C12.0624 0.0843123 11.8936 0.00542063 11.7151 0.000355601C11.5271 -0.00563034 11.3632 0.0642056 11.237 0.190985L11.152 0.276169C10.9104 0.518677 10.8989 0.907149 11.1259 1.16378C12.2455 2.42788 12.8624 4.05483 12.8624 5.74486C12.8624 7.36966 12.2862 8.94934 11.2404 10.1932C11.024 10.4507 11.0401 10.8312 11.2781 11.0691L11.3633 11.1543C11.4853 11.2763 11.6502 11.3442 11.8219 11.3442C11.8311 11.3442 11.8402 11.3439 11.8491 11.3436ZM7.1443 10.1234C7.1443 10.3749 6.99895 10.6035 6.77148 10.7101C6.68353 10.7517 6.58929 10.7718 6.49567 10.7718C6.3474 10.7718 6.20051 10.7209 6.08187 10.6225L2.53666 7.68477H0.648477C0.290395 7.68508 0 7.39453 0 7.03645V4.45282C0 4.09459 0.290395 3.80419 0.648477 3.80419H2.53681L6.08202 0.866475C6.27572 0.705929 6.54432 0.671702 6.77163 0.779142C6.99895 0.885814 7.14445 1.11451 7.14445 1.36592L7.1443 10.1234ZM9.63645 9.11004C9.62064 9.11111 9.60544 9.11173 9.58979 9.11173C9.4185 9.11173 9.25334 9.04404 9.13132 8.92186L9.0446 8.83484C8.81714 8.60783 8.79043 8.24821 8.98198 7.99005C9.46761 7.33528 9.72393 6.55911 9.72393 5.74486C9.72393 4.86907 9.43292 4.04762 8.88222 3.36921C8.67255 3.11136 8.69189 2.73685 8.92688 2.50202L9.01345 2.4153C9.14299 2.28576 9.31658 2.21515 9.50445 2.22636C9.6874 2.23557 9.85823 2.32167 9.97427 2.46349C10.7382 3.39822 11.1417 4.53309 11.1417 5.74502C11.1417 6.87375 10.7848 7.94815 10.1095 8.85157C9.99668 9.00214 9.82416 9.09668 9.63645 9.11004Z"
            fill="#E2E2E2"/>
    </svg>
    <div class="audio__input" v-if="hasAudio">
      <input type="range"
             min="0"
             max="1"
             step="0.01"
             id="range-value"
             @input="changeAudioSize"
             :value="volume"
      >
      <label for="range-value"></label>
    </div>
  </div>
  <div class="audio" v-else>
    <svg
        style="opacity: 0.5;cursor: none"
        width="10"
        height="11"
        viewBox="0 0 10 11"
        fill="none"
        class="audio__off"
        xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M1.1508 0.164386C0.88495 -0.059936 0.48743 -0.0564106 0.225207 0.185641C-0.0588671 0.447864 -0.0765815 0.890725 0.185641 1.1748L8.58564 10.2748L8.64921 10.3356C8.91506 10.5599 9.31258 10.5564 9.5748 10.3144C9.85887 10.0521 9.87659 9.60928 9.61437 9.32521L1.21437 0.225207L1.1508 0.164386ZM0.64848 3.80419H2.14531L7.14432 9.15941L7.1443 10.1234C7.1443 10.3749 6.99895 10.6035 6.77148 10.7101C6.68354 10.7517 6.5893 10.7718 6.49567 10.7718C6.3474 10.7718 6.20052 10.7209 6.08187 10.6225L2.53666 7.68477H0.64848C0.290398 7.68508 3.4942e-06 7.39453 3.4942e-06 7.03645V4.45282C3.4942e-06 4.09459 0.290398 3.80419 0.64848 3.80419ZM7.14445 1.36592L7.14437 6.08199L3.93587 2.64487L6.08203 0.866479C6.27573 0.705933 6.54433 0.671705 6.77164 0.779145C6.99895 0.885818 7.14445 1.11451 7.14445 1.36592Z"
            fill="#E2E2E2"/>
    </svg>
  </div>
</template>

<script>
    export default {
      props:{
        hasAudio: Boolean,
        volume: Number
      },
        name: "IntefaceAudio",
        methods: {
            changeAudio(size) {
              document.documentElement.style.setProperty('--range', (size * 100) + '%')
              this.$emit('changeVolume',size)
            },
            changeAudioSize(e) {
                const val = Number.parseFloat(e.target.value)
                if (val > 0) {
                    this.changeAudio(val)
                } else {
                    this.changeAudio(0)
                }

            }
        },
        watch: {
            getVolumeSize(size) {
                console.log(size)
                document.documentElement.style.setProperty('--range', (size * 100) + '%')
            }
        },
        mounted() {
            document.documentElement.style.setProperty('--range', (this.getVolumeSize * 100) + '%')
        }
    }
</script>

<style scoped lang="scss">
:root {
  --range: 0%
}

.audio {
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    vertical-align: middle;
    position: relative;
    top: 2px;
    cursor: pointer;
  }


  &__input {
    margin: 0 4px;
    position: relative;
    top: -2px;

    input {
      display: inline-block;
      appearance: none;
      position: relative;
      width: 50px;
      cursor: pointer;
      height: 3px;
      //background: #E2E2E2;
      //opacity: 0.1;
      background-color: transparent;
      border-radius: 2.45px;

      &::before {
        position: absolute;
        content: '';
        width: var(--range);
        border-radius: 2.45px;
        height: 3px;
        background: #E2E2E2;
      }

      &::-webkit-slider-runnable-track {
        height: 3px;
        border-radius: 2.45px;
        background-color: rgba(226,226,226,.1);
      }

      &::-webkit-slider-thumb {
        height: 3px;
        width: 3px;
        appearance: none;
        border-radius: 2.45px;
        //background-color: #E2E2E2;


        background: rgb(226,226,226);
        width: 10px;
        height: 10px;
        border-radius: 5px;
        margin-top: -3.5px;
        z-index: 2;
      }
    }

    label {
      background-color: #7e8bc6;
    }
  }
}
</style>
